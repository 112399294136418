"use client";

import Image from 'next/image'
import styles from './index.module.scss';
import LogoFooter from '@/app/images/logo-footer.png';
import {navItems} from "./data";
import IconButton from "@/app/_components/Shared/IconButton";
import FacebookIcon from "@/app/icons/facebook.svg";
import InstagramIcon from "@/app/icons/instagram.svg";
import XIcon from "@/app/icons/x-icon.svg";
import WhatsappIcon from "@/app/icons/whatsapp-black.svg";
import LinkedInIcon from "@/app/icons/linkedin.svg";
import CallIcon from "@/app/icons/call.svg";
import MailWhiteIcon from "@/app/icons/mail-white.svg";
import Link from 'next/link'

export default function Footer() {
    return (
        <footer className={styles.container}>
            <div className={styles.footer}>
                <div className={styles.row}>
                    <Image className={styles.logo} src={LogoFooter} alt="logo"/>
                    <p>Fast, Smart, Compliant</p>
                    <div className={styles.buttons}>
                        <a href="mailto:info@ninjaz.ae">
                            <IconButton icon={MailWhiteIcon} onClick={() => {}}>
                                info@ninjaz.ae
                            </IconButton>
                        </a>
                        <a href="tel:+97148355670">
                            <IconButton icon={CallIcon} color="secondary" onClick={() => {}}>
                                +971 4 8355670
                            </IconButton>
                        </a>
                    </div>
                </div>
                <div className={styles.row}>
                    <ul className={styles.list}>
                        {navItems.map((item, index) => (
                            <li key={index}>
                                <Link href={item.link}>
                                    {item.text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className={styles.icons}>
                        <Link href="https://www.facebook.com/Ninjazae">
                            <Image src={FacebookIcon} alt="facebook" />
                        </Link>
                        <Link href="https://www.instagram.com/ninjaz.ae?igsh=MW50ZGFsc2ZpcG9peA==">
                            <Image src={InstagramIcon} alt="instagram" />
                        </Link>
                        <Link href="https://www.linkedin.com/company/ninjaz">
                            <Image src={LinkedInIcon} alt="linkedin" />
                        </Link>
                        {/*<Link href="">*/}
                        {/*    <Image src={XIcon} alt="x-icon" />*/}
                        {/*</Link>*/}
                        <Link href="https://api.whatsapp.com/send?phone=971564691212">
                            <Image src={WhatsappIcon} alt="x-icon" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}