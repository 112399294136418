import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/CookieAlert/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/Footer/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/Nav/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/whatsapp.png");
