"use client";

import CookieConsent from "react-cookie-consent";

export default function CookieAlert(props) {
    return (
        <CookieConsent
            location="bottom"
            style={{ background: "rgb(103, 10, 193)" }}
            buttonStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                borderRadius: '8px',
                background: '#FFCD00',
                color: '#222',
                textAlign: 'center',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '26px', // 144.444%
                padding: '8px 16px',
                cursor: 'pointer',
                outline: 0,
                border: 'none',
            }}
            {...props}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
}