"use client";

import Image from 'next/image';
import MainButton from '@/app/_components/Shared/MainButton';

export default function IconButton({
    children,
    icon = null,
    type = 'button',
    color = 'primary',
    onClick,
    disabled = false,
    isLoading = false,
   }) {
    return (
        <MainButton type={type} onClick={onClick} color={color} disabled={disabled}>
            {icon && <Image src={icon} alt='icon' />}
            {isLoading && <span className="loader"></span>}
            {children}
        </MainButton>
    );
}