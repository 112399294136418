'use client';

import styles from './index.module.scss';
import { useRouter } from 'nextjs-toploader/app';

export default function MainButton({
    children,
    type = "button",
    onClick,
    color = 'primary',
    disabled = false
   }) {
    const router = useRouter();
    let className = styles.primary;
    if (color === 'secondary') {
        className = styles.secondary
    } else if (color === 'info') {
        className = styles.info
    } else if (color === 'danger') {
        className = styles.danger
    }
    const go = () => {
        onClick ? onClick() : router.push('/contact');
    }

    return (
        <button disabled={disabled} className={`${styles.button} ${className}`} type={type} onClick={go}>{children}</button>
    );
}